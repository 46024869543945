<template>
  <v-card-text class="text-center">
    <v-layout row wrap v-if="!filter">
      <v-flex lg2 md3 sm3 xs12>
        <v-tooltip bottom>
           <template v-slot:activator="{ on }">
          <v-btn  color="primary" @click="avanzado()" v-on="on">
            <v-icon dark v-text="'mdi-account-search'"></v-icon>AVANZADO
          </v-btn>
           </template>
          <span>Busqueda avanzada</span>
        </v-tooltip>
      </v-flex>
      <v-flex lg9 md8 sm7 xs12>
        <v-form ref="formBasico" v-model="validBasico" lazy-validation>
          <v-text-field
            label="Ingrese CI o Nombres o Apellido Paterno"
            prepend-icon="search"
            @keydown="resp=false"
            @keypress.prevent.enter="busqueda()"
            clearable
            v-model="search"
            :rules="searchRules"
          ></v-text-field>
        </v-form>
      </v-flex>
      <v-flex lg1 md1 sm1 xs12>
        <v-btn color="primary light2" :loading="peticion" @click="busqueda()">buscar</v-btn>
      </v-flex>
    </v-layout>
    <v-form v-else ref="formAbogadoAvanzado" v-model="validAvanzado" lazy-validation>
      <v-layout row wrap :mx-4="this.$vuetify.breakpoint.width<960">
        <v-flex lg1 md1 sm1 xs1 hidden-md-and-down>
          <v-tooltip bottom>
            <template  v-slot:activator="{ on }">
            <v-btn icon color="primary"  v-on="on" @click="basico()">
              <v-icon dark v-text="'mdi-magnify'">search</v-icon>
            </v-btn>
            </template>
            <span>Busqueda Rapida</span>
          </v-tooltip>
        </v-flex>
        <v-flex lg3 md2 sm6 xs12>
          <v-text-field
            label="Numero Documento"
            v-model="filtro.numeroDocumento"
            @keypress.prevent.enter="busqueda()"
            :rules="filtro.numeroDocumento?ruleBusqueda:[]"
            persistent-hint
            hint="No es necesario ingresar Expedición"
            prepend-icon="mdi-contacts"
          ></v-text-field>
        </v-flex>
        <v-flex lg2 md2 sm6 xs12>
          <v-text-field
            label="Nombres"
            v-model="filtro.nombres"
            @keypress.prevent.enter="busqueda()"
            prepend-icon="mdi-account"
            :rules="filtro.nombres?ruleBusqueda:[]"
          ></v-text-field>
        </v-flex>
        <v-flex lg2 md2 sm6 xs12>
          <v-text-field
            label="Primer Apellido"
            v-model="filtro.primerApellido"
            @keypress.prevent.enter="busqueda()"
            prepend-icon="mdi-account"
            :rules="filtro.primerApellido?ruleBusqueda:[]"
          ></v-text-field>
        </v-flex>
        <v-flex lg2 md2 sm6 xs12>
          <v-text-field
            label="Segundo Apellido"
            v-model="filtro.segundoApellido"
            @keypress.prevent.enter="busqueda()"
            prepend-icon="mdi-account"
            :rules="filtro.segundoApellido?ruleBusqueda:[]"
          ></v-text-field>
        </v-flex>
        <div id="panelResultados"></div>
        <v-flex lg2 md2 sm12 xs12 class>
          <v-btn
            color="primary lighten-2"
            :loading="peticion"
            @click="busqueda()"
            :disabled="!validAvanzado"
             
          >buscar</v-btn>
          <v-btn v-if="solicitud"  class="mt-1"  color="error" @click="limpiarBusqueda()">Limpiar Busqueda</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <div v-if="items">
      <v-layout row wrap v-if="totalItems>16">
        <v-flex lg12>
          <v-alert
            type="info"
            color="primary lighten-3"
            class="py-2 subtitle"
            :value="true"
            outlined
          >
            Resultados para la busqueda:
            <strong>{{totalItems}}</strong>Coincidencia(s)
          </v-alert>
        </v-flex>
      </v-layout>
      <v-data-table
        v-if="$vuetify.breakpoint.width<960 && solicitud"
        :headers="headers"
        :items="items"
        :server-items-length="totalItems"
        hide-default-footer
        class="elevation-5 px-2"
        :options.sync="pagination"
        no-data-text="Sin Datos"
        :loading="peticion"
      >
        <template slot="items" slot-scope="props">
          <tr @click="mostrarInfoAbogado(props.item)">
            <td class="text-xs-left">{{props.item.nombreCompleto}}</td>
            <td class="text-xs-left">{{props.item.numeroDocumento}}</td>
          </tr>
        </template>
      </v-data-table>
      <v-data-iterator
        v-else-if="items.length>0 || solicitud"
        :items="items"
        :server-items-length="totalItems"
        :options.sync="pagination"
        hide-default-footer
        content-tag="v-layout"
        row
        wrap
      >
        <div slot="header" class="font-weight-thin hidden-md-and-down">
          <p class="caption">
            <span class="font-weight-black">Nota:</span>
            {{nota}}
          </p>
        </div>
        <template v-slot:default="props">
          <v-layout row wrap>
            
         
        <v-flex xs12 sm6 md4 lg3 xl2 d-flex slot="item" v-for="item in props.items" :key="item.name">
          <v-hover>
            <v-card class="elevation-10" slot-scope="{ hover }">
              <v-card-title primary-title>
                <p class="subtitle-1 font-weight-medium text-justify">{{ item.nombreCompleto }}              
                <v-icon slot="activator" color="primary lighten-3" icon v-text="'mdi-information'"></v-icon>
                </p>
              </v-card-title>
              <v-img
                v-if="item.fotoAbogado!=null"
                :src="url+item.fotoAbogado"
                aspect-ratio="1.9"
                contain
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in primary lighten-2 v-card--reveal white--text"
                    style="height: 100%;"
                  >
                    <v-layout row wrap class="caption pa-1">
                      <v-flex lg5 xs5>NUMERO DOCUMENTO :</v-flex>
                      <v-flex lg7 xs7 class="text-xs-left">{{ item.numeroDocumento }}</v-flex>
                      <v-flex xs5>UNIVERSIDAD :</v-flex>
                      <v-flex xs7 class="text-xs-left">{{ item.nombreUniversidad }}</v-flex>
                      <v-flex xs5 class>DIRECCION OFICINA:</v-flex>
                      <v-flex xs7 class="text-xs-left">{{ item.direccion }}</v-flex>
                      <v-flex xs5>CELULAR:</v-flex>
                      <v-flex xs7 class="text-xs-left">{{ item.celular }}</v-flex>
                    </v-layout>
                  </div>
                </v-expand-transition>
              </v-img>
              <v-img v-else :src="require('@/assets/sinFoto.png')" contain></v-img>
              <v-card-text>
                <v-layout row wrap>
                  <v-flex lg5 class="caption">MATRICULA:</v-flex>
                  <v-flex lg7 class="text-xs-left">{{ item.matricula }}</v-flex>
                  <v-flex xs5 class="caption">FECHA REGISTRO:</v-flex>
                  <v-flex xs7 class="text-xs-left">{{ formatDate(item.fechaCreacion) }}</v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-flex>
         </v-layout>
        </template>
        <v-flex lg12 slot="no-data">
          <div v-show="search==null && peticion">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div v-show="search!=null && resp">
            <v-alert type="error" :value="true">Sin Resultados para la busqueda</v-alert>
          </div>
        </v-flex>
        <v-flex lg12 slot="no-results">
          <div class="title">Espere un momento ...</div>
        </v-flex>
      </v-data-iterator>
      <v-layout row wrap v-if="totalItems>16">
        <v-flex xs12>
          <div class="text-xs-center">
            <v-pagination v-model="pagination.page" :length="pages" :total-visible="5"></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <v-dialog
      v-if="$vuetify.breakpoint.width<960 && dialogAbogado"
      v-model="dialogAbogado"
      persistent
      :overlay="false"
      max-width="900px"
      transition="dialog-transition"
    >
      <v-card class="elevation-10">
        <v-toolbar color="primary lighten-2" dark>
          <div class="title font-weight-medium">{{ abogadoSeleccionado.nombreCompleto }}</div>
          <v-spacer></v-spacer>
          <v-btn icon text dark @click="dialogAbogado=false" small>
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <div style="height:10px" class="hidden-md-and-up"></div>
        <v-img
          class="pt-2"
          v-if="abogadoSeleccionado.fotoAbogado!=null"
          :src="url+abogadoSeleccionado.fotoAbogado"
          aspect-ratio="2"
          contain
        ></v-img>
        <v-img v-else :src="require('@/assets/sinFoto.png')" contain></v-img>
        <v-card-text class="texto-principal">
          <v-layout row wrap>
            <v-flex xs5 class="text-xs-right pr-2 caption">MATRICULA:</v-flex>
            <v-flex xs7 class="text-xs-left font-weight-bold">{{ abogadoSeleccionado.matricula }}</v-flex>
            <v-flex xs5 class="text-xs-right pr-2 caption">FECHA REGISTRO:</v-flex>
            <v-flex
              xs7
              class="text-xs-left font-weight-bold"
            >{{ formatDate(abogadoSeleccionado.fechaCreacion) }}</v-flex>
            <v-flex xs5 class="text-xs-right pr-2 caption">NUMERO DOCUMENTO :</v-flex>
            <v-flex
              xs7
              class="text-xs-left font-weight-bold"
            >{{ abogadoSeleccionado.numeroDocumento }}</v-flex>
            <v-flex xs5 class="text-xs-right pr-2 caption">UNIVERSIDAD :</v-flex>
            <v-flex
              xs7
              class="text-xs-left font-weight-bold"
            >{{ abogadoSeleccionado.nombreUniversidad }}</v-flex>
            <v-flex xs5 class="text-xs-right pr-2 caption">DIRECCION OFICINA:</v-flex>
            <v-flex xs7 class="text-xs-left font-weight-bold">{{ abogadoSeleccionado.direccion }}</v-flex>
            <v-flex xs5 class="text-xs-right pr-2 caption">CELULAR:</v-flex>
            <v-flex xs7 class="text-xs-left font-weight-bold">{{ abogadoSeleccionado.celular }}</v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <p style="font-size:9px">
            <span class="font-weight-black">Nota:</span> La presente información no constituye una certificación y/o acreditación legal alguna para abogadas
          </p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
export default {
  data: () => ({
    filter: true,
    resp: false,
    solicitud: false,
    filtro: {
      nombres: null,
      primerApellido: null,
      segundoApellido: null,
      numeroDocumento: null
    },
    nota:
      " La presente información no constituye una certificación y/o acreditación legal alguna para abogadas, abogados o terceras personas, y solo deberá ser considerada de manera referencial. Las certificaciones que acrediten el registro y matriculación de abogados y abogadas, deberán ser recabadas en oficinas del Registro Público de la Abogacía dependiente del Ministerio de Justicia.",
    search: null,
    searchRules: [
      v => !!v || "El campo no puede estar vacio",
      v => (v && v.length >= 4) || "El campo debe tener al menos 4 caracteres"
    ],
    validBasico: true,
    validAvanzado: true,
    url: null,
    totalItems: 0,
    peticion: false,
    pagination: {
      rowsPerPage: 16
    },
    ruleBusqueda: [
      v => (v && v.length >= 3) || "El campo debe tener al menos 3 caracteres"
    ],
    headers: [
      {
        text: "Nombres",
        value: "nombres",
        align: "left",
        sortable: false
      },
      {
        text: "Numero Documento",
        value: "numeroDocumento",
        align: "left",
        sortable: false
      }
    ],
    items: [],
    dialogAbogado: false,
    abogadoSeleccionado: {}
  }),
  mounted: function() {
    if (this.$vuetify.breakpoint.width > 960) {
      this.$vuetify.goTo(60);
    } else {
      this.$vuetify.goTo(0);
    }
    this.url = "https://apirpa2.justicia.gob.bo/";
  },
  computed: {
    pages() {
      if (this.totalItems == null) return 0;
      return Math.ceil(this.totalItems / this.pagination.rowsPerPage);
    }
  },
  watch: {
    pagination: {
      handler() {
        if (this.solicitud) {
          this.$vuetify.goTo("#panelResultados", {});
          this.busqueda();
        }
      },
      deep: true
    }
  },

  methods: {
    avanzado() {
      this.search = null;
      this.filter = !this.filter;
    },
    basico() {
      this.filtro.nombres = null;
      this.filtro.primerApellido = null;
      this.filtro.segundoApellido = null;
      this.filtro.numeroDocumento = null;
      this.filter = !this.filter;
    },
    busqueda() {
      this.items = [];
      this.formato();
      if (!this.filter && this.$refs.formBasico.validate()) {
        this.solicitud = true;
        this.consulta();
      } else if (
        this.filter &&
        (this.filtro.nombres != null ||
          this.filtro.primerApellido != null ||
          this.filtro.segundoApellido != null ||
          this.filtro.numeroDocumento != null) &&
        this.$refs.formAbogadoAvanzado.validate()
      ) {
        this.solicitud = true;
        this.consulta();
      } else {
        this.resp = true;
        this.items = [];
        this.totalItems = 0;
        this.$notify({
          group:'foo',
          type:'error',
          title:'ERROR EN BUSQUEDA',
          text:'Debe ingresar al menos un parametro en formato correcto para realizar la busqueda'
        })
      }
    },
    formato() {
      this.search = this.search == "" ? null : this.search;
      this.filtro.nombres =
        this.filtro.nombres == "" ? null : this.filtro.nombres;
      this.filtro.primerApellido =
        this.filtro.primerApellido == "" ? null : this.filtro.primerApellido;
      this.filtro.segundoApellido =
        this.filtro.segundoApellido == "" ? null : this.filtro.segundoApellido;
      this.filtro.numeroDocumento =
        this.filtro.numeroDocumento == "" ? null : this.filtro.numeroDocumento;
    },
    consulta() {
      var config = {
        params: {
          query: this.search,
          nombres: this.filtro.nombres,
          primerApellido: this.filtro.primerApellido,
          segundoApellido: this.filtro.segundoApellido,
          numeroDocumento: this.filtro.numeroDocumento,
          pag: this.pagination.page,
          limit: this.pagination.rowsPerPage
        }
      };
      this.peticion = true;
      this.$http
        .get(`${this.$apiUrl}buscar`, config)
        .then(response => {
          this.peticion = false;
          this.items = response.data.data.items;
          this.totalItems = response.data.data.total_count;
          if (this.totalItems == 0) {
             this.$notify({
          group:'foo',
          type:'error',
          title:'ADVERTENCIA',
          text:'Sin Resultados para la busqueda'
        })
          } else {
            this.resp = true;
            this.$vuetify.goTo("#panelResultados", {});
          }
        })
        .catch(error => {
          this.resp = true;
          this.desserts = [];
          this.peticion = false;
          this.totalDesserts = 0;
          if (error.response) {
            if (error.response.status == 404) {
              this.$store.commit(
                "setError",
                "Error en la peticion al servidor"
              );
            }
          }
        });
    },
    formatDate(date) {
      if (!date) return null;
      const [dates, hours] = date.split(" ");
      if (date.indexOf("-") > 0) {
        const [year, month, day] = dates.split("-");
        return `${day}/${month}/${year}`;
      }
      return date;
    },
    mostrarInfoAbogado(data) {
      this.abogadoSeleccionado = data;
      this.dialogAbogado = true;
    },
    limpiarBusqueda() {
      this.search = null;
      this.resp = false;
      this.items = [];
      this.solicitud = false;
      this.totalItems = 0;
      this.filtro.nombres = null;
      this.filtro.numeroDocumento = null;
      this.filtro.primerApellido = null;
      this.filtro.segundoApellido = null;
      if (this.$vuetify.breakpoint.width > 960) {
        this.$vuetify.goTo(60);
      } else {
        this.$vuetify.goTo(0);
      }
    }
  }
};
</script>